import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv } from '@/utils/helpers';
import postNewTasq from '@/graphql/predictions/mutations/postNewTasq.graphql';
import updateStatus from '@/graphql/predictions/mutations/updateStatus.graphql';
import unsnoozeJob from '@/graphql/predictions/mutations/unsnoozeJob.graphql';
import reassignTasq from '@/graphql/workflow/mutations/reassignTasq.graphql';
import deleteTasq from '@/graphql/workflow/mutations/deleteTasq.graphql';
import predictionsApolloClient from '@/lib/appsync/workflow';
import userResponsesApolloClient from '@/lib/appsync/workflow';
import postJobComment from '@/graphql/workflow/mutations/postJobComment.graphql';
import workflowApolloClient from '@/lib/appsync/workflow';
import updateWorkTicketTasq from '@/graphql/workflow/mutations/updateWorkTicketTasq.graphql';
// import postGlobalWellComment from '@/graphql/workflow/mutations/postGlobalWellComment.graphql';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import updateUserResponse from '@/graphql/userResponses/mutations/updateUserResponse';
import postJobResponseV2 from '@/graphql/userResponses/mutations/postJobResponseV2';
// import getProceduresStepsJson from '@/graphql/procedures/queries/getProceduresStepsJson.graphql';
import accountModule from '@/store/modules/accountModule';
import metaDataModule from '@/store/modules/metaDataModule';
// import proceduresApolloClient from '@/lib/appsync/procedures';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqActionsModule',
  store,
})
class TasqActionsModule extends VuexModule {
  @Action
  async addTasq(data: any = {}) {
    const {
      assetType,
      person,
      well,
      jobType,
      frequency,
      delayedDays,
      comment,
      stateChangeDate,
      stateChangeDateEnd,
      actions,
    } = data;
    try {
      const {
        data: {
          post_new_tasq: {
            id: predictionID,
            workflow_task_id: workflowTaskID,
          },
        },
      } = await predictionsApolloClient.mutate({
        mutation: postNewTasq,
        variables: {
          input: {
            level: assetType,
            assigned_to_user: typeof person === 'object' ? person.value : person,
            node_id: well,
            prediction_type: typeof jobType === 'object' ? jobType.value : jobType,
            ...stateChangeDate && {
              state_change_date: new Date(stateChangeDate).toISOString().slice(0, -5),
            },
            ...stateChangeDateEnd && {
              state_change_date_end: new Date(stateChangeDateEnd).toISOString().slice(0, -5),
            },
            ...frequency === 'Reoccurance' && {
              recurring_start_date: new Date().toISOString(),
              recurring_end_date: new Date(
                new Date().getTime() + 86400000 * delayedDays,
              ).toISOString(),
            },
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
      if (jobType) {
        let jobTypeComment = jobType.key !== jobType.value ? `Job Type: ${jobType.key} - ${jobType.value}` : `Job Type: ${jobType.key}`;
        if (comment) {
          jobTypeComment = `${jobTypeComment} - Remarks: ${comment}`;
        }

        // const {
        //   data: {
        //     post_job_comment: {
        //       comment_id: jobCommentId,
        //     },
        //   },
        // } = await workflowApolloClient.mutate({
        //   mutation: postJobComment,
        //   variables: {
        //     input: {
        //       prediction_id: predictionID,
        //       username: typeof person === 'object' ? person.value.toLowerCase() : person.toLowerCase(),
        //       comment: jobTypeComment,
        //       should_post_as_global_well_comment: false,
        //     },
        //   },
        // });

        if (comment || (actions && actions.length)) {
          const jobFormResponsePayload = {
            predictionID,
            workflowTaskID,
            completeForToday: false,
            closeTasq: false,
            formResponse: actions,
            nodeID: well,
            validationResponse: { selectionActionComment: '', selectionAction: 'YES' },

          };

          await metaDataModule.postJobFormsResponse(jobFormResponsePayload);
          // await tasqFeedbackModule.leaveFeedbackV2(leaveFeedback);

          // const input = {
          //   NodeID: well,
          //   PredictionID: predictionID,
          //   CloseTasq: false,
          //   Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          //   Username: accountModule.user.email.toLowerCase(),
          //   Validate: '',
          //   ValidateComment: comment,
          //   Systems: [],
          //   SystemsComment: '',
          //   Symptoms: [],
          //   SymptomsComment: '',
          //   Causes: [],
          //   Stepper: actions ? 2 : 1,
          //   CausesComment: '',
          //   ProceduresJSONVersion: '1646922636.6159925',
          //   Actions: actions || [],
          //   ActionsComment: '',
          //   WorkflowTaskID: workflowTaskID,
          // };

          // await userResponsesApolloClient.mutate({
          //   mutation: postJobResponseV2,
          //   variables: {
          //     input,
          //   },
          // });
        }

        // await workflowApolloClient.mutate({
        //   mutation: postGlobalWellComment,
        //   variables: {
        // 	  input: {
        //       NodeID: well,
        //       Level: assetType,
        //       Username: accountModule.user.email.toLowerCase(),
        //       JobType: jobType.key,
        //       Comment: jobTypeComment,
        //       Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        //       PredictionID: predictionID,
        //       CommentID: jobCommentId,
        //       ResponseData: JSON.stringify(input),
        // 	  },
        //   },
        //   });
      }

      // if (comment) {
      //   await workflowApolloClient.mutate({
      //     mutation: postJobComment,
      //     variables: {
      //       input: {
      //         prediction_id: predictionID,
      //         username: typeof person === 'object' ? person.value : person,
      //         comment,
      //         should_post_as_global_well_comment: false,
      //       },
      //     },
      //   });
      // }
      return predictionID;
    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async addWorkTicket(data: any = {}) {
    const {
      assetType,
      well,
      jobType,
      frequency,
      delayedDays,
      stateChangeDate,
      stateChangeDateEnd,
      jobCateogry,
      requestDetails,
      requestCompletionDate,
      files,
      checklist,
      priority,
      details,
    } = data;
    try {
      const {
        data: {
          post_new_tasq: {
            id: predictionID,
            workflow_task_id: workflowTaskID,
          },
        },
      } = await predictionsApolloClient.mutate({
        mutation: postNewTasq,
        variables: {
          input: {
            level: assetType,
            assigned_to_user: accountModule.user.email,
            node_id: well,
            prediction_type: jobType,
            ...stateChangeDate && {
              state_change_date: new Date(stateChangeDate).toISOString().slice(0, -5),
            },
            ...stateChangeDateEnd && {
              state_change_date_end: new Date(stateChangeDateEnd).toISOString().slice(0, -5),
            },
            ...frequency === 'Reoccurance' && {
              recurring_start_date: new Date().toISOString(),
              recurring_end_date: new Date(
                new Date().getTime() + 86400000 * delayedDays,
              ).toISOString(),
            },
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            tasq_type: 'Work Ticket',
            job_category: jobCateogry,
            job_type: jobType,
            request_details: requestDetails,
            request_completion_date: requestCompletionDate,
            files: JSON.stringify(files),
            detailed_steps: JSON.stringify(checklist),
            priority,
            details: JSON.stringify(details),
          },
        },
      });
      return predictionID;
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }



  @Action
  async updateWorkTicket(data: any = {}) {
    const {
      assetType,
      well,
      jobType,
      assignedToUser,
      jobCateogry,
      requestDetails,
      requestCompletionDate,
      files,
      checklist,
      priority,
      details,
    } = data;
    try {
      const {
        data: {
          update_work_ticket_tasq: {
            id: predictionID,
            workflow_task_id: workflowTaskID,
          },
        },
      } = await predictionsApolloClient.mutate({
        mutation: updateWorkTicketTasq,
        variables: {
          input: {
            level: assetType,
            assigned_to_user: assignedToUser,
            node_id: well,
            tasq_type: 'Work Ticket',
            job_category: jobCateogry,
            job_type: jobType,
            request_details: requestDetails,
            request_completion_date: requestCompletionDate,
            files: JSON.stringify(files),
            detailed_steps: JSON.stringify(checklist),
            priority,
            details: JSON.stringify(details),
            tasq_status: {
              Status: 'Inprogress',
	            // # Open, In Progress, Blocked, Completed
              Reason: 'Reason',
              StatusStartDate: '11/9/2022',
              StatusEndDate: '11/9/2022',
              TicketStartDate: '11/9/2022',
              TicketDueDate: '11/9/2022',
            }
          },
        },
      });
      return predictionID;
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async addActionTasq({
    well,
    actionName,
    parentWorkflowID,
  }) {
    try {
      const {
        data: {
          post_new_tasq: {
            id: predictionID,
            workflow_task_id: workflowTaskID,
          },
        },
      } = await predictionsApolloClient.mutate({
        mutation: postNewTasq,
        variables: {
          input: {
            level: 'Well',
            assigned_to_user: accountModule.user.email.toLowerCase(),
            node_id: well,
            prediction_type: actionName,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            tasq_type: 'Action',
            parent_workflow_task_id: parentWorkflowID,
          },
        },
      });

      return predictionID;
    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async deleteTasq({
    id,
  }) {
    try {
      await predictionsApolloClient.mutate({
        mutation: deleteTasq,
        variables: {
          input: {
            ID: id,
          },
        },
      });
    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async postNewWellComment(comment) {
    try {
      await workflowApolloClient.mutate({
        mutation: postJobComment,
        variables: {
          input: {
            prediction_id: tasqsListModule.activeTasq?.id,
            username: accountModule.user.email.toLowerCase(),
            comment,
            should_post_as_global_well_comment: true,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  @Mutation
  async mutateTasqReassign({ comment, person, id }) {
    await workflowApolloClient.mutate({
      mutation: reassignTasq,
      variables: {
        input: {
          id,
          username: typeof person === 'object' ? person.value.toLowerCase() : person.toLowerCase(),
          comment,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }

  @Action
  async reassignTasq(tasq) {
    const {
      person,
      comment,
    } = tasq;
    if ((tasqsListModule.isBatchReassign || tasqsListModule.isActionUnReleated) && tasqsListModule.checkedTasqs) {
      for (let i = 0; i < tasqsListModule.checkedTasqs.length; i++) {
        // eslint-disable-next-line no-param-reassign
        tasq.id = tasqsListModule.checkedTasqs[i];
        // eslint-disable-next-line no-await-in-loop
        await this.mutateTasqReassign(tasq);
      }
    } else {
      // console.log(tasqsListModule.activeTasq)
      // eslint-disable-next-line no-param-reassign
      tasq.id = tasqsListModule.activeTasq?.id;
      await this.mutateTasqReassign(tasq);
    }

    if (tasqsListModule.isActionUnReleated) {
      tasqsListModule.resetChekedTasqs();
      tasqsListModule.setIsActionUnReleated(false);
    }
  }

  @Action
  async rejectTasq({
    reason,
  }) {
    await predictionsApolloClient.mutate({
      mutation: updateStatus,
      variables: {
        input: {
          PredictionID: tasqsListModule.activeTasq?.id,
          Rejected: {
            Status: true,
            Reason: reason,
            // operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        //   operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }

  @Mutation
  async mutateTasqUpdateStatus({
    reason, comment = '', date, id, startDate,
  }) {
    const newComment = `${reason} ${comment.length ? `-Remarks: ${comment}` : ''}`;
    let waitStartDate: any = new Date().toISOString().slice(0, -5);
    if (startDate) {
      waitStartDate = new Date(startDate).toISOString().slice(0, -5);
    }
    await predictionsApolloClient.mutate({
      mutation: updateStatus,
      variables: {
        input: {
          PredictionID: id,
          //   Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          Snoozed: {
            Status: true,
            UntilDate: new Date(date).toISOString().slice(0, -5),
            WaitUntilReason: newComment,
            WaitStartDate: waitStartDate,
            // Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      },
    });
  }

  @Action
  async updateTasqStatus(action) {
    if ((tasqsListModule.isBatchReassign || tasqsListModule.isActionUnReleated) && tasqsListModule.checkedTasqs) {
      for (let i = 0; i < tasqsListModule.checkedTasqs.length; i++) {
        // eslint-disable-next-line no-param-reassign
        action.id = tasqsListModule.checkedTasqs[i];
        // eslint-disable-next-line no-await-in-loop
        await this.mutateTasqUpdateStatus(action);
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      action.id = tasqsListModule.activeTasq?.id;
      await this.mutateTasqUpdateStatus(action);
    }

    if (tasqsListModule.isActionUnReleated) {
      tasqsListModule.resetChekedTasqs();
      tasqsListModule.setIsActionUnReleated(false);
    }
  }

  @Action
  async unsnoozeJob(id) {
    await predictionsApolloClient.mutate({
      mutation: unsnoozeJob,
      variables: {
        PredictionID: id,
      },
    });
  }
}

export default getModule(TasqActionsModule);
