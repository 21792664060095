import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { decodeGorillaCompression } from '@/utils/testGorillaDecode.js';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import getNodeDescriptionCombinations from '@/graphql/cleanData/queries/getNodeDescriptionCombinations.graphql';
import getCleanTSDataTimeRangeV2 from '@/graphql/cleanData/queries/getCleanTSDataTimeRangeV2.graphql';
import getCleanTSDataTimeRangeV2ChartIQ from '@/graphql/cleanData/queries/getCleanTSDataTimeRangeV2ChartIQ.graphql';
import cleanDataApolloClient from '@/lib/appsync/cleanData';
import localForage from '@/lib/localForage';

@Module({
  dynamic: true,
  namespaced: true,

  name: 'tasqSignalsModule',
  store,
})
class TasqSignalsModule extends VuexModule {
  availableSignals = getConfigEnv('SIGNALS_ARRAY');

  currentSignals: any = [];

  currentPlungerSignals: any = [];

  selectedSignals: any = [];

  selectedPlungerSignals: any = [];

  editableCurrentSignals: any = [];

  editableCurrentPlungerSignals: any = [];

  chartIQSignals: any = [];

  signalDescriptions: any = [];

  plungerSignalDescriptions: any = [];

  didLoadAllSignals: boolean = false;

  didLoadAllPlungerSignals: boolean = false;

  signalChartTime = 60;

  @Mutation
  resetSignals(): void {
    this.currentSignals = [];
  }

  @Mutation
  resetPlungerSignals(): void {
    this.currentPlungerSignals = [];
  }

  @Mutation
  resetChartIQSignals(): void {
    this.chartIQSignals = [];
  }

  @Mutation
  resetSelectedSignals(): void {
    this.selectedSignals = [];
  }

  @Mutation
  setSelectedSignals(data): void {
    this.selectedSignals = data;
  }

  @Mutation
  resetSelectedPlungerSignals(): void {
    this.selectedPlungerSignals = [];
  }

  @Mutation
  setSelectedPlungerSignals(data): void {
    this.selectedPlungerSignals = data;
  }

  @Mutation
  setSignalChartTime(data): void {
    this.signalChartTime = data;
  }

  @Mutation
  selectSignal(signal_name): void {
    if (this.selectedSignals.indexOf(signal_name) > -1) {
      this.selectedSignals.splice(this.selectedSignals.indexOf(signal_name), 1);
    } else {
      this.selectedSignals.push(signal_name);
    }
  }

  @Mutation
  selectPlungerSignal(signal_name): void {
    if (this.selectedPlungerSignals.indexOf(signal_name) > -1) {
      this.selectedPlungerSignals.splice(this.selectedPlungerSignals.indexOf(signal_name), 1);
    } else {
      this.selectedPlungerSignals.push(signal_name);
    }
  }

  @Mutation
  setDidLoadAllSignals(val): void {
    this.didLoadAllSignals = val;
  }

  @Mutation
  setDidLoadAllPlungerSignals(val): void {
    this.didLoadAllPlungerSignals = val;
  }

  @Mutation
  setSignalDescriptions(descriptions): void {
    this.signalDescriptions = descriptions;
  }

  @Mutation
  setPlungerSignalDescriptions(descriptions): void {
    this.plungerSignalDescriptions = descriptions;
  }

  @Mutation
  addPlungerSignal(signal): void {
    const currentSignalsNames = this.currentPlungerSignals.map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this.currentPlungerSignals.push(signal);
  }

  @Mutation
  addSignal(signal): void {
    const currentSignalsNames = this.currentSignals.map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this.currentSignals.push(signal);
  }

  @Mutation
  addEditableCurrentSignals(signal): void {
    const currentSignalsNames = this.editableCurrentSignals.map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this.editableCurrentSignals.push(signal);
  }

  @Mutation
  addChartIQSignal(signal): void {
    // const signalDataExist = this.chartIQSignals.find((s) => s.name);

    this.chartIQSignals.push(signal);
  }

  @Mutation
  setCurrentSignal(data): void {
    this.currentSignals = data;
  }

  @Action
  async getSignalsForTasq(data: any = 60): Promise<void> {
    // data = 60
    this.setDidLoadAllSignals(false);
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    const dataCount = 2000;
    let wellName = tasqsListModule.activeTasq?.wellName != null ? tasqsListModule.activeTasq?.wellName : assetsModule.activeTasq?.wellName;
    let daysLength = 60;
    const rawData = false;
    if (typeof data === 'object') {
      wellName = data.wellName;
      isOfflinePreloading = true;
      daysLength = data.daysLength;
    } else {
      daysLength = data;
      this.resetSignals();
    }
    this.resetSelectedSignals();
    const fetchSignalQuery = getCleanTSDataTimeRangeV2;

    if (!wellName) {
      return;
    }
    if (isOnlineApp) {
      const {
        data: {
          get_clean_ts_data_node_description_combinations_v2: nodeData,
        },
      } = await cleanDataApolloClient.query({
        query: getNodeDescriptionCombinations,
        variables: {
          operator: getConfigEnv('OPERATOR_PASCALED'),
          nodeid: wellName,
          filter: 'tasq_page',
        },
        fetchPolicy: 'network-only',
      });
      const backendSignals = jsonParse(nodeData)?.Description || [];
      // console.log(backendSignals);
      const signalsToFetch = backendSignals;
      //   signalsToFetch.splice(signalsToFetch.indexOf('Inj. Flowrate'), 1);

      const copiedSignalsToFetch = JSON.parse(JSON.stringify(signalsToFetch));
	  for (let g = 0; g < copiedSignalsToFetch.length; g++) {
        if (copiedSignalsToFetch[g] != 'Flowrate' && copiedSignalsToFetch[g] != 'Static Pressure' && copiedSignalsToFetch[g] != 'Tubing Pressure' && copiedSignalsToFetch[g] != 'Casing Pressure' && copiedSignalsToFetch[g] != 'Inj. Flowrate') {
          signalsToFetch.splice(signalsToFetch.indexOf(copiedSignalsToFetch[g]), 1);
        }
	  }

	  signalsToFetch.push('TP-LP');
	  signalsToFetch.push('CP-LP');
	  signalsToFetch.push('CP-TP');

	  // Just need floats for density, other than that you can use ints

      let fromTime = DateTime.utc().minus({ days: daysLength + tasqsListModule.fromNowBack }).toISO().slice(0, -5);
      const toTime = DateTime.utc().minus({ days: tasqsListModule.fromNowBack === 1 ? 0 : tasqsListModule.fromNowBack }).toISO().slice(0, -5);

      const offlineSignals: any = [];

      fromTime = (fromTime.split('T'))[0];

      tasqsListModule.setChartFromTime(fromTime);
      tasqsListModule.setChartToTime(toTime);

	  let signals_to_fetch_count = 0;
	  let already_added_index = 0;
	  const colors:any = [
        '#00FFEA', // teal
        '#F21688', // pink
        // "#FFFF5D", // yellow

        '#FFC8A5', // light orange
        '#FF94B8', // light pink
        // "#DB94C6", // light pink purple
        '#A994C6', // light purple
        '#94B0C6', // light blue
        '#94E2C6', // light green blue
        // "#94FFB0", // light green
        '#94ffdf', // light blue green
        '#94eaff', // light blue
        '#94c1ff', // another light blue
        '#a894ff', // light purple
        '#ffb194', // light orange
        '#90e64e', // lime green
        '#b14ee6', // purple
        '#41c459', // green
        '#41c4b0', // blue
      ];

      signalsToFetch.map(async (signal, idx) => {
        try {
          let raw = false;
          if (signal.toLowerCase() == 'tp-lp' || signal.toLowerCase() == 'cp-lp' || signal.toLowerCase() == 'cp-tp') {
            raw = false;
          }

          const {
            data:
          {
            get_clean_ts_data_time_range_v2: {
              dataset,
              description,
              units,
            },
          },
          } = await cleanDataApolloClient.query({
            query: fetchSignalQuery,
            variables: {
              nodeid: wellName,
              description: [signal],
              // downsample_method: `{"method": "drop_nth_element", "n": 1}`,
              from_time: fromTime,
              to_time: toTime,
              raw,
              operator: getConfigEnv('OPERATOR_PASCALED'),
              gorilla_compress: true,
              reg_freq: '5m',
              fillna_method: 'locf',

            },
            fetchPolicy: 'network-only',
          });
          const parsedDataset = JSON.parse(dataset);
          if (description.length > 0 && parsedDataset.Value[0].start != undefined) {
            const date = new Date();
            date.toISOString();

            if (parsedDataset.Value[0] == undefined) {
              parsedDataset.Value[0] = {
                series: [],
                start: DateTime.now().toISO(),
              };
            } else {
              parsedDataset.Value[0].series = decodeGorillaCompression(parsedDataset.Value[0].series, parsedDataset.Value[0].nb_values);
            }

            let color = 'pink';
            if (description[0].toLowerCase() == 'casing pressure') {
              color = '#DB94C6';
            } else if (description[0].toLowerCase() == 'tubing pressure') {
              color = '#94FFB0';
            } else if (description[0].toLowerCase() == 'inj. flowrate') {
              color = '#FFCD5C'; // light orange yellow';
            } else if (description[0].toLowerCase() == 'flowrate') {
              color = '#e64e4e'; // light orange yellow';
            } else if (description[0].toLowerCase() == 'static pressure') {
              color = '#FFFF5D';
            } else if (description[0].toLowerCase() == 'tp-lp') {
              color = '#696969';
            } else if (description[0].toLowerCase() == 'cp-tp') {
              color = '#baa79b';
            } else if (description[0].toLowerCase() == 'cp-lp') {
              color = '#c2c2c2';
            } else {
              already_added_index += 1;
              color = colors[already_added_index];
            }

            // 	if (entry.getText().toLowerCase() == "casing pressure"
            // 		|| entry.getText().toLowerCase() == "tubing pressure"
            // 		|| entry.getText().toLowerCase() == "static pressure"
            // 		|| entry.getText().toLowerCase() == "gas rate"
            // 		|| entry.getText().toLowerCase() == "oil rate"
            // 		|| entry.getText().toLowerCase() == "target"
            // 		|| entry.getText().toLowerCase() == "target band"
            // 		|| entry.getText().toLowerCase() == "water rate"
            // 		|| entry.getText().toLowerCase() == "deferment range") {
            // 		entry.setOn(true)
            // 	} else {
            // 		entry.setOn(false)

            let selected = true;
            if (description[0].toLowerCase() == 'casing pressure'
				|| description[0].toLowerCase() == 'tubing pressure'
				|| description[0].toLowerCase() == 'static pressure'
				|| description[0].toLowerCase() == 'gas rate'
				|| description[0].toLowerCase() == 'oil rate'
				|| description[0].toLowerCase() == 'target'
				|| description[0].toLowerCase() == 'target band'
				|| description[0].toLowerCase() == 'water rate'
				|| description[0].toLowerCase() == 'deferment range') {
              this.selectSignal(description[0]);
              selected = true;
            } else {
              selected = false;
            }

            const signalLocal = {
			  id: idx,
			  name: description[0],
			  dataset: parsedDataset.Value[0].series,
			  time: parsedDataset.Value[0].start,
			  unit: units[0],
			  color,
			  selected,
            };

            if (!isOfflinePreloading) {
			  this.addSignal(signalLocal);
			  this.addEditableCurrentSignals(signalLocal);
            }
            offlineSignals.push(signalLocal);

            signals_to_fetch_count += 1;
            if (signals_to_fetch_count == signalsToFetch.length) {
              this.setDidLoadAllSignals(true);
            }
          }
        } catch (error) {
          console.error(`Error: Failed to ${signal} signal data on ${wellName}, for ${daysLength} days`);
        }
      });
    }
  }

  @Action
  async getPlungerSignalsForTasq(data: any = 60): Promise<void> {
    // data = 60
    this.setDidLoadAllPlungerSignals(false);
    this.setPlungerSignalDescriptions([]);
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    const dataCount = 2000;
    let wellName = tasqsListModule.activeTasq?.wellName != null ? tasqsListModule.activeTasq?.wellName : assetsModule.activeTasq?.wellName;
    let daysLength = 60;
    const rawData = false;
    if (typeof data === 'object') {
      wellName = data.wellName;
      isOfflinePreloading = true;
      daysLength = data.daysLength;
    } else {
      daysLength = data;
    }
    this.resetPlungerSignals();
    this.resetSelectedPlungerSignals();
    const fetchSignalQuery = getCleanTSDataTimeRangeV2;

    if (!wellName) {
      return;
    }
    if (isOnlineApp) {
      const {
        data: {
          get_clean_ts_data_node_description_combinations_v2: nodeData,
        },
      } = await cleanDataApolloClient.query({
        query: getNodeDescriptionCombinations,
        variables: {
          operator: getConfigEnv('OPERATOR_PASCALED'),
          nodeid: wellName,
          filter: 'plunger',
        },
        fetchPolicy: 'network-only',
      });
      const backendSignals = jsonParse(nodeData)?.Description || [];
      // console.log(backendSignals);
      const rawSignalDetails = jsonParse(nodeData)?.raw || [];

      const detailsForEachSignal = backendSignals.map((des, i) => ({ name: des, raw: rawSignalDetails[i] }));

      // console.log(backendSignals);

      // 'Arrival Status', 'Open Reason' 'Tubing Length'
      const allowedSignals = [
        'Arrival Duration', 'Open Duration', 'Close Duration', 'Open Casing', 'Open Tubing', 'Open Line', 'Close Reason', 'Close Flowrate', 'Close Casing', 'Close Tubing', 'Close Line', 'Cycle Volume', 'Slug Volume',
        'Arrival Velocity', 'Open Tbg-Line', 'Open Csg-Line', 'Open Csg-Tbg', 'Close Tbg-Line', 'Close Csg-Line', 'Close Csg-Tbg', 'Open CP-LP', 'Open CP-TP', 'Open TP-LP', 'Afterflow Time', 'Close/Arrival Ratio',
      ];
      // console.log(backendSignals);
      const signalsToFetch = backendSignals.filter((signal) => allowedSignals.includes(signal));

      // console.error(signalsToFetch);

      // signalsToFetch.push('Open CP-LP');
      // signalsToFetch.push('Open TP-LP');
      // signalsToFetch.push('Open CP-TP');
      // signalsToFetch.push('Afterflow Time');
      // signalsToFetch.push('Close/Arrival Ratio');
      //   signalsToFetch.splice(signalsToFetch.indexOf('Inj. Flowrate'), 1);

	  // Just need floats for density, other than that you can use ints

      this.setPlungerSignalDescriptions(signalsToFetch);

  

      let fromTime = DateTime.utc().minus({ days: daysLength + tasqsListModule.fromNowBack }).toISO().slice(0, -5);
      const toTime = DateTime.utc().minus({ days: tasqsListModule.fromNowBack === 1 ? 0 : tasqsListModule.fromNowBack }).toISO().slice(0, -5);

      const offlineSignals: any = [];

      fromTime = (fromTime.split('T'))[0];

      tasqsListModule.setChartFromTime(fromTime);
      tasqsListModule.setChartToTime(toTime);

	  let signals_to_fetch_count = 0;
	  let already_added_index = 0;
	  const colors:any = [
        '#00FFEA', // teal
        '#F21688', // pink
        // "#FFFF5D", // yellow
        '#FFCD5C', // light orange yellow
        '#FFC8A5', // light orange
        '#FF94B8', // light pink
        // "#DB94C6", // light pink purple
        '#A994C6', // light purple
        '#94B0C6', // light blue
        '#94E2C6', // light green blue
        // "#94FFB0", // light green
        '#94ffdf', // light blue green
        '#94eaff', // light blue
        '#94c1ff', // another light blue
        '#a894ff', // light purple
        '#ffb194', // light orange
        '#90e64e', // lime green
        '#e64e4e', // light red
        '#b14ee6', // purple
        '#41c459', // green
        '#41c4b0', // blue
      ];

      // console.error(signalsToFetch);

      signalsToFetch.map(async (signal, idx) => {
        let raw = false;

        const findRaw = detailsForEachSignal.find((s) => s.name === signal);

        if (findRaw) {
          raw = findRaw.raw;
        }

        const {
          data:
          {
            get_clean_ts_data_time_range_v2: {
              dataset,
              description,
              units,
            },
          },
        } = await cleanDataApolloClient.query({
          query: fetchSignalQuery,
          variables: {
            nodeid: wellName,
            description: [signal],
            // downsample_method: `{"method": "drop_nth_element", "n": 1}`,
            from_time: fromTime,
            to_time: toTime,
            raw,
            operator: getConfigEnv('OPERATOR_PASCALED'),
            gorilla_compress: true,
            reg_freq: '5m',
            fillna_method: 'null',

          },
          fetchPolicy: 'network-only',
        });

        const parsedDataset = JSON.parse(dataset);
        if (description.length > 0 && parsedDataset.Value[0].start != undefined) {
          const date = new Date();
          date.toISOString();

          if (parsedDataset.Value[0] == undefined) {
            parsedDataset.Value[0] = {
              series: [],
              start: DateTime.now().toISO(),
            };
          } else {
            parsedDataset.Value[0].series = decodeGorillaCompression(parsedDataset.Value[0].series, parsedDataset.Value[0].nb_values);
          }
          let axisName = 'axisY';
          let color = 'pink';
          if (description[0].toLowerCase() == 'Arrival Duration'.toLowerCase()) {
            color = '#FF6B6B';
            axisName = 'axisY';
          } else if (description[0].toLowerCase() == 'Arrival Velocity'.toLowerCase()) {
            color = '#00FFC2';
            axisName = 'axisY3';
          } else if (description[0].toLowerCase() == 'Open Duration'.toLowerCase()) {
            color = '#B38ED7';
            axisName = 'axisY';
          } else if (description[0].toLowerCase() == 'Close Duration'.toLowerCase()) {
            color = '#854BBF';
            axisName = 'axisY';
          } else if (description[0].toLowerCase() == 'Open Casing'.toLowerCase()) {
            color = '#F687FF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open Tubing'.toLowerCase()) {
            color = '#CEFBCA';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open Line'.toLowerCase()) {
            color = '#FFFB9F';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Flowrate'.toLowerCase()) {
            color = '#FF0000';
            axisName = 'axisY3';
          } else if (description[0].toLowerCase() == 'Close Casing'.toLowerCase()) {
            color = '#EB00FF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Tubing'.toLowerCase()) {
            color = '#3AEF2A';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Line'.toLowerCase()) {
            color = '#FFF500';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Cycle Volume'.toLowerCase()) {
            color = '#D9D9D9';
            axisName = 'axisY4';
          } else if (description[0].toLowerCase() == 'Slug Volume'.toLowerCase()) {
            color = '#FFC0BF';
            axisName = 'axisY4';
          } else if (description[0].toLowerCase() == 'Open Tbg-Line'.toLowerCase()) {
            color = '#003FFF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open Csg-Line'.toLowerCase()) {
            color = '#6087FF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open Csg-Tbg'.toLowerCase()) {
            color = '#BFCFFF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Tbg-Line'.toLowerCase()) {
            color = '#FF8000';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Csg-Line'.toLowerCase()) {
            color = '#FFB060';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Csg-Tbg'.toLowerCase()) {
            color = '#FFDFBF';
            axisName = 'axisY2';
          }else if (description[0].toLowerCase() == 'Open CP-LP'.toLowerCase()) {
            color = '#00F0FF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open CP-TP'.toLowerCase()) {
            color = '#9022FF';
            axisName = 'axisY2';
          }else if (description[0].toLowerCase() == 'Open TP-LP'.toLowerCase()) {
            color = '#00FF19';
            axisName = 'axisY2';
          }else if (description[0].toLowerCase() == 'Afterflow Time'.toLowerCase()) {
            color = '#FF2667';
            axisName = 'axisY';
          }else if (description[0].toLowerCase() == 'Close/Arrival Ratio'.toLowerCase()) {
            color = '#97C2E1';
            axisName = 'axisY4';
          }else {
            already_added_index += 1;
            color = '#FFFF';
          }

          //   || description[0].toLowerCase() == 'Open tbg-line'
          // || description[0].toLowerCase() == 'Open csg-line'
          // || description[0].toLowerCase() == 'open csg-tbg'
          // || description[0].toLowerCase() == 'close tbg-line'
          // || description[0].toLowerCase() == 'flowrate close'
          // || description[0].toLowerCase() == 'arrival velocity'

          let selected = true;
          if (description[0].toLowerCase() == 'open cp-lp'
				|| description[0].toLowerCase() == 'open tp-lp'
        || description[0].toLowerCase() == 'afterflow time'
        || description[0].toLowerCase() == 'open line'
				|| description[0].toLowerCase() == 'open duration'
        || description[0].toLowerCase() == 'close duration'
          ) {
            this.selectPlungerSignal(description[0]);
            selected = true;
          } else {
            selected = false;
          }
          

          const signalLocal = {
			  id: idx,
			  name: description[0],
			  dataset: parsedDataset.Value[0].series,
			  time: parsedDataset.Value[0].start,
			  unit: units[0],
			  color,
            axisName,
			  selected,
          };

          this.addPlungerSignal(signalLocal);

          signals_to_fetch_count += 1;
          if (signals_to_fetch_count == signalsToFetch.length) {
            this.setDidLoadAllPlungerSignals(true);
          }
        }
      });
    }
  }

  @Action
  async getSignalsForTasqForChartIQ(daysBack = 0): Promise<void> {
    const wellName = tasqsListModule.activeTasq?.wellName != null ? tasqsListModule.activeTasq?.wellName : assetsModule.activeTasq?.wellName;
    let rawData = false;
    this.resetChartIQSignals();
    const fetchSignalQuery = getCleanTSDataTimeRangeV2ChartIQ;
    rawData = true;

    // if (!tasqsListModule.activeTasq?.wellName && !assetsModule.activeTasq?.wellName) return;

    if (!wellName) {
      return;
    }
    // const daysBack = [0, 30, 60, 90, 120, 150, 180];

    const signalNames = ['Tubing Pressure', 'Flowrate', 'Casing Pressure', 'Static Pressure'];

    const fromTime = DateTime.utc().minus({ days: daysBack === 0 ? 31 : 30 + daysBack }).toISO().slice(0, -5);
    const toTime = DateTime.utc().minus({ days: daysBack <= 0 ? 0 : daysBack - 1 }).toISO().slice(0, -5);

    signalNames.map(async (signal, idx) => {
      let raw = false;
      if (signal.toLowerCase() == 'tp-lp' || signal.toLowerCase() == 'cp-lp' || signal.toLowerCase() == 'cp-tp') {
        raw = false;
      }
      const {
        data:
          {
            get_clean_ts_data_time_range_v2: {
              dataset,
              description,
              units,
            },
          },
      } = await cleanDataApolloClient.query({
        query: fetchSignalQuery,
        variables: {
          nodeid: wellName,
          description: [signal],
          from_time: fromTime,
          raw,
          to_time: toTime,
          operator: getConfigEnv('OPERATOR_PASCALED'),
		  gorilla_compress: true,
		  fillna_method: 'locf',
        },
        fetchPolicy: 'network-only',
      });
      const parsedDataset = JSON.parse(dataset);

      const signalLocal = {
        id: idx,
        name: description[0],
        dataset: parsedDataset.Value[0],
        time: parsedDataset.time[0],
        unit: units[0],
      };

      this.addChartIQSignal(signalLocal);
    });
  }
}

export default getModule(TasqSignalsModule);
